<template>
    <div class="mt-30">
        <div class="row">
            <div class="col-md-6" >
                <h3 class="page-header m-0">
                <i class="fa fa fa-rupee animated bounceInDown show-info"></i> Lottery Record</h3>
            </div>
        </div>
        <div class="col-md-12 pt-10">
            <div class="">
                <table class="table ">
                    <thead>
                        <th>S.No.</th>
                        <th>VS-DIGI</th>
                        <th>Name</th>
                        <th>Rewards</th>                        
                        <th>Position</th>
                        <th>Created</th>
                    </thead>
                    <tbody>
                        <tr v-for="(item,index) in lotteries" :key="item.id">
                            <td>{{ index+1 }}</td>
                            <td>{{ item.generatedid ?? 'N/A' }}</td>
                            <td>{{ item.customername ?? 'N/A' }}</td>
                            <td>{{ item.rewards ?? 'N/A' }}</td>                            
                            <td>{{ item.position ?? 'N/A' }}</td>
                           <td>{{ moment(item.created_at).format('YYYY-MM-DD hh:mm:ss A') }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import moment from 'moment';
import { mapGetters } from 'vuex'

export default {
    data() {
        return {
            lotteries: [],
            moment: moment,
        }
    },
    mounted() {
        this.refresh();
    },
    computed: {
        ...mapGetters([  ]),
    },
    methods: {
        refresh(){
            let param = {fromdate: this.fromdate, todate: this.todate}
            axios.post('api/lottery/record/fetch',param)
                .then((response)=>{ 
                    console.log(response)
                    this.lotteries = response.data.records;
                 })
                .catch((error) => console.log(error));
        }
     
    }
}
</script>